<template>
  <div class="overflow-hidden">
    <SiteNav />
    <div class="">
      <section class="bg-white relative">
        <div class="hero bg-landingGrey pb-10 md:pb-0 pt-12 lg:pt-20 overflow-hidden">
          <div class="l-container">
            <div class="text-center flex flex-col-reverse lg:text-left lg:grid grid-cols-2 gap-8 items-center">
              <div class="">
                <p class="tagline">DEVICE MANAGEMENT</p>
                <h1 class="heading level-2 mb-4">Manage employee devices from anywhere in the world.</h1>
                <p class="text-summary md:w-4/5 mx-auto lg:mx-0 mb-6">
                  Configure and secure employees’ gadgets and devices from one dashboard.
                </p>
                <SiteButton color="primary" shadow to="/login"> Get started </SiteButton>
              </div>
              <div class="max-w-2xl mx-auto">
                <img src="./assets/img-3.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="hidden md:flex w-full flex-col absolute -bottom-24 md:-bottom-32">
          <img class="w-full h-14 md:h-20" src="./assets/swiggleSingle.png" />
          <img class="w-full h-14 md:h-20" src="./assets/swiggleSingle.png" />
        </div>
        <div class="flex md:hidden w-full flex-col absolute -bottom-24 md:-bottom-28">
          <img class="w-full h-28" src="./assets/mobileWiggle.png" />
        </div>
      </section>

      <section class="mt-52 flex justify-center">
        <div
          class="pb-12 md:pb-24 w-screen"
          :style="{
            backgroundImage: ' url(' + background + ')',
          }"
          style="background-size: cover; background-position: center; background-repeat: no-repeat"
        >
          <div class="flex justify-center">
            <div class="flex bg-newAsh rounded-full p-3 mx-auto mb-10">
              <div
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{
                  'bg-white ': activeTab === tab,
                  '': activeTab !== tab,
                }"
                @click="activeTab = tab"
                class="py-2 px-4 cursor-pointer tab-item rounded-full"
              >
                <span class="hidden md:inline w-full text-sm">
                  {{ tab }}
                </span>
                <span class="inline md:hidden w-full text-sm">
                  {{ tab === "Control app access & device usage" ? "App Control " : tab }}
                </span>
              </div>
            </div>
          </div>

          <div class="w-11/12 md:w-8/12 mx-auto">
            <div v-if="activeTab === 'Onboarding'" class="flex flex-col md:flex-row items-center gap-12">
              <img src="./assets/tab1Image.png" class="w-96 md:w-100 h-64 md:h-72" />
              <div class="flex flex-col text-center md:text-left">
                <p class="font-bold text-2xl md:text-3xl mb-4 md:mb-0">Onboarding</p>
                <p>
                  Easily place an order, track or retrieve employees’ devices. Easily manage employees' devices, apps,
                  data and security – no matter where they are – in one unified system.
                </p>
              </div>
            </div>
            <div v-else-if="activeTab === 'Offboarding'" class="flex flex-col md:flex-row items-center gap-12">
              <img src="./assets/tab2Image.png" class="w-96 md:w-100 h-64 md:h-72" />
              <div class="flex flex-col text-center md:text-left">
                <p class="font-bold text-2xl md:text-3xl mb-4 md:mb-0">Offboarding</p>
                <p>
                  Securely and easily offboard employees and contractors so that you ensure company information stays
                  private. Manually lock or wipe devices, transfer files, and deprovision apps.
                </p>
              </div>
            </div>
            <div
              v-else-if="activeTab === 'Control app access & device usage'"
              class="flex flex-col md:flex-row items-center gap-5 pt-11 md:pt-12"
            >
              <img src="./assets/tab3Image.png" class="w-96 md:w-102-px h-52 md:h-60" />
              <div class="flex flex-col text-center md:text-left">
                <p class="font-bold text-xl md:text-2xl md:mb-0">Control app access and device usage</p>
                <p class="my-4 text-grey text-base font-normal">Apps that we support:</p>
                <img src="./assets/supportIcons.svg" class="w-full" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="my-32 w-full md:w-10/12 mx-auto">
        <div class="l-container">
          <p class="text-center font-bold text-2xl md:text-4xl mb-12">Why choose us ?</p>

          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full md:w-9/12 mx-auto gap-6">
            <DeviceCard
              :IconSrc="paperIcon"
              title="New device onboarding"
              description="You can always onboard new devices for your organization."
            />
            <DeviceCard
              :IconSrc="deviceIcon"
              title="Disable devices during offboarding"
              description="You can disable devices at will when needed."
            />
            <DeviceCard
              :IconSrc="shieldIcon"
              title="Secure lost or stolen devices"
              description="Secure lost or stolen devices with lock or wipe options."
            />
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-11/12 md:w-full mx-auto gap-6 mt-6">
            <DeviceCard
              :IconSrc="printIcon"
              title="Manage passwords and security policies"
              description="Implement, manage and secure passwords with ease."
            />
            <DeviceCard
              :IconSrc="fanIcon"
              title="Easily monitor device condition"
              description="Keep an eye on your device's condition effortlessly with our intuitive monitoring tools."
            />
            <DeviceCard
              :IconSrc="cardIcon"
              title="Control app access and device usage"
              description="Be 100% in charge of app access and device usage."
            /><DeviceCard
              :IconSrc="gridIcon"
              title="Real time update of all applications"
              description="Never miss a beat with our real-time updates on all your applications."
            />
          </div>
        </div>
      </section>
      <section
        :style="{
          backgroundImage: ' url(' + background2 + ')',
        }"
        style="background-size: cover; background-position: center; background-repeat: no-repeat"
        class="bg-primary mb-20 bg-opacity-100 rounded-4xl hidden md:flex py-16 flex-col justify-center items-center gap-9 w-10/12 mx-auto"
      >
        <p class="text-white font-bold text-3xl">
          Integrated with JumpCloud, we help you secure all <br />
          employee devices from one simple dashboard.
        </p>
        <SiteButton color="bg-mint text-primary text-sm" shadow to="/login"> Get started for free </SiteButton>
      </section>
      <section
        :style="{
          backgroundImage: ' url(' + mobileBackground2 + ')',
        }"
        style="background-size: cover; background-position: center; background-repeat: no-repeat"
        class="bg-primary text-center mb-12 bg-opacity-100 rounded-4xl md:hidden flex py-10 flex-col justify-center items-center gap-9 w-11/12 mx-auto"
      >
        <p class="text-white font-bold text-xl">
          Integrated with JumpCloud, we <br />
          help you secure all employee <br />
          devices from one simple <br />
          dashboard.
        </p>
        <SiteButton color="bg-mint text-primary text-sm" shadow to="/login"> Get started for free </SiteButton>
      </section>
    </div>
    <SiteFooter />
  </div>
</template>

<script setup>
import { ref } from "vue";
import SiteFooter from "../../components/SiteFooter.vue";
import SiteNav from "../../components/SiteNav.vue";
import SiteButton from "../../components/SiteButton.vue";
import DeviceCard from "../../components/DeviceCard.vue";
import background from "./assets/tabsBackground.svg";
import background2 from "./assets/people.png";
import mobileBackground2 from "./assets/mobileBackground2.png";
import paperIcon from "./assets/paperIcon.svg";
import deviceIcon from "./assets/device2.svg";
import shieldIcon from "./assets/shield2.svg";
import printIcon from "./assets/print.svg";
import fanIcon from "./assets/fan.svg";
import cardIcon from "./assets/card.svg";
import gridIcon from "./assets/grid.svg";

const tabs = ref(["Onboarding", "Offboarding", "Control app access & device usage"]);
const activeTab = ref("Onboarding");
</script>

<style lang="scss" scoped>
@import url("../../styles/www.scss");

.hero {
  .heading {
    color: #292a29;
  }
}

.freedom-section {
  background: #f8f8f8;
  .heading {
    font-family: "Roboto";
    span {
      color: #ea4e4b;
    }
  }
}

.what-we-offer-section {
  .heading {
    font-family: "Roboto";
  }
}
</style>
